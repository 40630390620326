<template>
    <div class="details card">
        <img
            class="top-left-ribbon"
            src="../assets/top_left_corner_ribbon.png"
        >
        <h1 class="gold header-text-cursive highlight-header">
            Andrew's 2020 highlights
        </h1>
        <div class="info-section">
            <h2>I moved</h2>
            <p>
                I moved to Waukee! I'm actually just around the corner from Josh and Teresa. Currently renting a new
                townhouse while I pay off loans and save up for a real house.
                Email me for the new address if you need it! <a
                    href="mailto:andrew@gorman.rocks"
                >andrew@gorman.rocks</a>
            </p>
            <img src="../assets/205.jpeg">
        </div>
        <div class="info-section">
            <h2>I worked</h2>
            <p>
                Still working at IMT Insurance, still loving it! I'm now a Full Stack Web Developer I, and I am
                anticipating a promotion to Full Stack Web Dev II this spring!
            </p>
        </div>
        <div class="info-section">
            <h2>I adopted Delta</h2>
            <p>I adopted a kitten this spring!</p>
            <img src="../assets/delta_kitten.jpeg">
            <p>He's grown so much (but still sleeps the same excessive amount)</p>
            <img src="../assets/delta_large.jpeg">
        </div>
        <div class="info-section">
            <h2>I got fit</h2>
            <p>I re-joined Farrells Kickboxing and won the summer 2020 10-week challenge!</p>
            <img src="../assets/winning.jpeg">
        </div>
        <div class="info-section">
            <h2>2021</h2>
            <p>
                In the next year, my goals are to finish up my <a href="https://www.andrewgorman.dev/">personal
                    website</a> and <a href="https://portfolio.andrewgorman.dev/">portfolio</a>
                and to start in on some fun personal projects, as well as to continue learning Spanish and French, and
                to earn some certifications in my field! I’m also considering learning guitar!
            </p>
        </div>
        <div class="info-section">
            <h2>I'd love to hear from you!</h2>
            <p>
                Thanks for reading through our Christmas card! I’d love to hear from you and hear how 2020 went for you!
                Drop me a text or email! When you do, be sure to update me with any change of email/number/address you
                might have!
            </p>
            <p>
                My new email(s) are <a href="mailto:andrew@gorman.rocks">andrew@gorman.rocks</a> (for personal things)
                and <a href="mailto:andrew@andrewgorman.dev">andrew@andrewgorman.dev</a> (for professional things)
            </p>
        </div>
        <div style="display: flex; justify-content: space-around;">
            <router-link
                :to="{name: 'Home'}"
                class="christmas-button"
            >
                Back to home
            </router-link>
            <router-link
                :to="{name: 'Details'}"
                class="christmas-button"
            >
                Want to read more?
            </router-link>
        </div>
        <div class="info-section">
            <h2>Bloopers</h2>
            <p>Getting that cover photo was a challenge, enjoy these two bloopers!</p>
            <img src="../assets/outtake_2.jpeg">
            <img src="../assets/outtake_1.jpeg">
        </div>
        <img
            class="bottom-right-ribbon"
            src="../assets/bottom_right_corner_ribbon.png"
        >
    </div>
</template>
<script>

    export default {
        name: 'Highlights',
        mounted() {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    };
</script>
<style lang="sass">
    $stupid-font-path: "../assets"
    @import "@/styles/variables.sass"

    html, body
        .website-container
            .card
                .highlight-header
                    font-size: 3.5rem
                    text-align: center

</style>
